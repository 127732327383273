
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class SubscribersService {
    getAll()
    {
        return instance.get(`${API_URL}/api/admin/subscribe/`,{
            withCredentials:true
        })
    }

    sendMessageToAllSubscribers(request)
    {
        return instance.post(`${API_URL}/api/admin/subscribe/toall/`,request,{
            withCredentials:true
        })
    }
}
export default new SubscribersService()
