import instance from '../auth/api';
import {API_URL} from '../../Constants'

instance.defaults.withCredentials = true
class UsersService {

    getUsersFromDatabase()
    {
        return instance.get(`${API_URL}/api/admin/users/`)
    }

    updateUser(user)
    {
        return instance.post(`${API_URL}/api/admin/users/update/`,user)
    }

    deleteUser(user)
    {
        return instance.post(`${API_URL}/api/admin/users/delete/`,user)
    }

    addRole(payload)
    {
        return instance.post(`${API_URL}/api/admin/users/role/add/`,payload)
    }

    deleteRole(payload)
    {
        return instance.post(`${API_URL}/api/admin/users/role/delete/`,payload)
    }

}
export default new UsersService()
