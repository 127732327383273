import instance from '../auth/api';
import {API_URL} from '../../Constants'
instance.defaults.withCredentials = true
class AuthorityService {

    getAll()
    {
        return instance.get(`${API_URL}/api/admin/authority/`,{
            withCredentials:true
        })
    }

    add(principal)
    {
        return instance.post(`${API_URL}/api/admin/authority/`,principal,{
            withCredentials:true
        })
    }

    update(principal)
    {
        return instance.put(`${API_URL}/api/admin/authority/`,principal,{
            withCredentials:true
        })
    }

    delete222(director)
    {
        return instance.post(`${API_URL}/api/admin/authority/delete/`,director,{
            withCredentials:true
        })
    }
    uploadimage(file)
    {
        return instance.post(`${API_URL}/api/admin/images/upload/`,file,{
            withCredentials:true
        })
    }

}
export default new AuthorityService()
