import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  UsersCountryMenu2, Languages, IsActive
} from "../../constant";
import NewsService from "../../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import AuthorityService from "../../api/Axios/AuthorityService";
import GalleryPictureService from "../../api/Axios/GalleryPictureService";
import Select from "react-select";
const AddNews = () => {

  const localeoptions = [
    { label: 'kirill', value: 'kirill' },
    { label: 'uz', value: 'uz' },
    { label: 'ru', value: 'ru' },

  ]
  const[imageUploaded,setimageUploaded]=useState(false)
  const [content,setcontent]=useState('');
  const [title,settitle]=useState('');
  const [imageUUID,setimageUUID]=useState([]);
  const[showdropzone,setshowdropzone]=useState(true)
  const[locale,setlocale]=useState('kirill')
  const[mainimage,setmainimage]=useState('')
  
  const addPrincipal =()=>{

    let readyNews={
      'content':content,
      'locale':locale,
      'images':imageUUID,
      'mainimage':mainimage,
      'title':title
    }

    NewsService.addnews(readyNews).then((response)=>{toast.success("News has been added successfully")
      setTimeout(function(){window.location.reload()}, 2000);})

  }
  const onChangeInEditor = (event, editor) => {
    const data = editor.getData()

    setcontent(data)

  }
  const updateContent=(newContent)=> {
    setcontent(newContent)
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


  const handleChangeStatus = ({ meta, file }, status) => {  }

   const handleSubmit = (files, allFiles) => {

    for(let i=0;i<files.length;i++)
    {
      let formData=new FormData()
      formData.append("file",files[i].file)
      GalleryPictureService.uploadimagetominio(formData).then((response)=>{
        imageUUID.push(response.data)
        if(i===files.length-1){
          toast.success("Pictures Added Successfully")
        }
      })
    }
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)

  }

  const handleSubmitMainImage = (files, allFiles) => {
    let formData=new FormData()
    formData.append("file",files[0].file)

    AuthorityService.uploadimage(formData).then((response)=>{
      setmainimage(response.data)
      toast.success("Image has been uploaded successfully")

    })
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)

  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                    <FormGroup>
                      <Label className="form-label">Language</Label>

                      <Select
                          options={localeoptions}
                          onChange={value => setlocale(value.value)}
                          defaultValue={{ label: "KIRILL", value: 'kirill'}}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="validationCustom01">Title</Label>
                      <Input className="form-control" id="validationCustom01" type="text"  onChange={(event)=>settitle(event.target.value)} />

                   </FormGroup>


                    <div className="email-wrapper">
                      <div className="theme-form">
                        <FormGroup>
                          <Label>Content</Label>
                          <CKEditor
                              data=""
                              onChange={evt => setcontent( evt.editor.getData() )}
                          />

                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Form>
                <Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    Main Image
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmitMainImage}
                        accept="image/*"
                        maxFiles={1}
                        canCancel={true}
                        multiple={false}
                    />
                  </div>
                </Form>
                <Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    Other Images
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*"
                        canCancel={true}
                        multiple={true}
                    />

                  </div>
                </Form>
                <div className="btn-showcase">

                  {imageUploaded&&<Button color="primary" type="submit" onClick={addPrincipal}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AddNews
