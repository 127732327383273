import {API_URL} from "../../Constants";
import instance from "../auth/api";
class MessageService {

    leaveMessage(message)
    {
        return instance.post(`${API_URL}/api/message/`,message,{
            withCredentials:true
        })
    }
    getall()
    {
        return instance.get(`${API_URL}/api/message/all/`,{
            withCredentials:true
        })
    }
    getanswered()
    {
        return instance.get(`${API_URL}/api/admin/message/answered/`,{
            withCredentials:true
        })
    }
    getunanswered()
    {
        return instance.get(`${API_URL}/api/admin/message/unanswered/`,{
            withCredentials:true
        })
    }

    updateMesForAdmin(m)
    {
        return instance.post(`${API_URL}/api/admin/message/update/`,m,{
            withCredentials:true
        })
    }

    deleteMesForAdmin(m)
    {
        return instance.post(`${API_URL}/api/admin/message/delete/`,m,{
            withCredentials:true
        })
    }

}
export default new MessageService()
