
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class DocFileService {


    getAll()
    {
        return instance.get(`${API_URL}/api/admin/docfile/`)
    }

    add(data)
    {
        return instance.post(`${API_URL}/api/admin/docfile/`, data)}

    update(object,year,chorak,locale,typ)
    {
        return instance.post(`${API_URL}/api/admin/docfile/update?year=${year}&chorak=${chorak}&locale=${locale}&typ=${typ}`, object)
    }
    delete(object)
    {
        return instance.post(`${API_URL}/api/admin/docfile/delete/`, object)
    }


}
export default new DocFileService()
