import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class GalleryPictureService {

  getAllFatherPictures(){
     return instance.get(`${API_URL}/api/admin/jobimage/`)
  }
  getFamilyPics(familyid)
  {
      return instance.get(`${API_URL}/api/jobimage/${familyid}`)
  }

  deleteOnePic(pic)
  {
      return instance.post(`${API_URL}/api/admin/jobimage/delete/`,pic)
  }

    addGalleryPicture(gPicture)
    {
        return instance.post(`${API_URL}/api/admin/jobimage/`, gPicture)
    }
    deleteGroup(familyid)
    {
        return instance.get(`${API_URL}/api/admin/jobimage/delete/${familyid}`)
    }
    updateFamily(gPicture)
    {
        return instance.put(`${API_URL}/api/admin/jobimage/`, gPicture)
    }
    setMainGalleryJobPicture(gPicture)
    {
        return instance.post(`${API_URL}/api/admin/jobimage/setmain/`, gPicture)
    }
}
export default new GalleryPictureService()
