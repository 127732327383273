import React, {Fragment, useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  Languages
} from "../constant";
import NewsService from "../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import GalleryPictureService from "../api/Axios/GalleryPictureService";
import { v4 as uuidv4 } from 'uuid';
import GalleryJobPictureService from "../api/Axios/GalleryJobPictureService";
const GalleryPicturePost = () => {

  const[imageUploaded,setimageUploaded]=useState(false)
  const [description,setdescription]=useState('');
  const [imageUUID,setimageUUID]=useState([]);
  const[showdropzone,setshowdropzone]=useState(true)
  const[lang,setlang]=useState('kirill')

  const forceUpdate = useForceUpdate();
  const AddNews =()=>{
  let familyUUID=uuidv4();
    for(let i=0;i<imageUUID.length;i++)
    {
      if(i===0)
    {
      let gPicture={
        'description':description,
        'family':familyUUID,
        'isfather':true,
        'image':imageUUID[i],
        'locale':lang
      }

      GalleryJobPictureService.addGalleryPicture(gPicture).then((response)=>{toast.success("Picture Added Successfully")})

    }
    else {

    let gPicture={
      'description':description,
      'family':familyUUID,
      'isfather':false,
      'image':imageUUID[i],
      'locale':lang
    }
        GalleryJobPictureService.addGalleryPicture(gPicture).then((response)=>{toast.success("Picture Added Successfully")})
        if(i===imageUUID.length-1){
          setTimeout(function(){window.location.reload()}, 2000);}

   }
  }}

    // called every time a file's `status` changes
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {  }


  const fsetdescription=(event)=>{
    setdescription(event.target.value)
  }

  const setLang = (event) => {
    setlang(event.target.value)
  }

  const handleSubmit = (files, allFiles) => {

    for(let i=0;i<files.length;i++)
    {
      let formData=new FormData()
      formData.append("file",files[i].file)
      GalleryPictureService.uploadimagetominio(formData).then((response)=>{
      imageUUID.push(response.data)
      if(i===files.length-1){
      toast.success("Pictures Added Successfully")
      }
    })
    }
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)

  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Gallery" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Gallery Pictures</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                    <FormGroup>
                      <Label className="form-label">Language</Label>
                      <Input type="select" name="select" className="form-control btn-square" onChange={setLang}>
                        {Languages.map((items,i) =>
                            <option key={i}  value={items.valueOf()} onChange={setLang} >{items}</option>
                        )}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="validationCustom01">Description:</Label>
                      <Input className="form-control"  type="text"  required=""onChange={fsetdescription}  />
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </FormGroup>

                  </Col>
                </Form>
                {!imageUploaded&&<Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*"
                        canCancel={true}
                        multiple={true}
                    />

                  </div>
                </Form>}
                <div className="btn-showcase">
                  {imageUploaded&&<Button color="primary" type="submit" onClick={AddNews}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GalleryPicturePost
