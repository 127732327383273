import React, {Fragment, useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {PostEdit,Title,Type,Category,Content,Post,Discard,Text,Audio,Video,Image} from "../constant";
import NewsService from "../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';

import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import VideoService from "../api/Axios/VideoService";
const GalleryVideoPost = () => {

    const localeoptions = [
        { label: 'kirill', value: 'kirill' },
        { label: 'uz', value: 'uz' },
        { label: 'ru', value: 'ru' },

    ]
    const [imageUploaded, setimageUploaded] = useState(false)

    const [title_uz, settitle_uz] = useState('');
    const [description_uz, setdescription_uz] = useState('');
    const [locale, setlocale] = useState('kirill');


    const [imageUUID, setimageUUID] = useState('');
    const [showdropzone, setshowdropzone] = useState(true)

  const AddNews =()=>{
 {
     let gPicture = {
         'locale': locale,
         'title': title_uz,
         'description': description_uz,

         'video': imageUUID
     }

   VideoService.addnews(gPicture).then((response)=>
       {toast.success("Video has been uploaded successfully")
           setTimeout(function(){window.location.reload()}, 2000);
       }
   )

  }}


  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = ({ meta, file }, status) => {  }

  const fsettitle_uz=(event)=>{
    settitle_uz(event.target.value)
  }
  const fsetdescription_uz=(event)=>{
    setdescription_uz(event.target.value)
  }

  const handleSubmit = (files, allFiles) => {
    let formData=new FormData()
    formData.append("file",files[0].file)
      VideoService.uploadimagetominio(formData).then((response)=>{
      setimageUUID(response.data)
      toast.success("Video has been successfully uploaded to server storage")

        setimageUploaded(true)
        setshowdropzone(false)
    })
    allFiles.forEach(f => f.remove())



  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                      <FormGroup>
                          <Label className="form-label">Language</Label>
                          <Select
                              options={localeoptions}
                              onChange={value => setlocale(value.value)}
                              defaultValue={{ label: "KIRILL", value: 'kirill' }}
                          />
                      </FormGroup>
                    <FormGroup>
                        <Label for="validationCustom01">Title </Label>
                        <Input className="form-control" id="validationCustom0213213" type="text" required=""
                               onChange={fsettitle_uz} name={title_uz}/>
                        <Label for="validationCustom01">Description:</Label>
                        <Input className="form-control" id="validationCustom0213213213" type="text" required=""
                               onChange={fsetdescription_uz} name={description_uz}/>
                    </FormGroup>
                  </Col>
                </Form>
                {!imageUploaded&&<Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="video/mp4"
                        canCancel={true}
                        multiple={false}
                        maxFiles={1}
                    />
                  </div>
                </Form>}
                <div className="btn-showcase">
                  {imageUploaded&&<Button color="primary" type="submit" onClick={AddNews}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GalleryVideoPost
