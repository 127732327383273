
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class GalleryPictureService {

    uploadimagetominio(image)
    {
        return instance.post(`${API_URL}/api/admin/images/upload/`, image)}





}
export default new GalleryPictureService()
