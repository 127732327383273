import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {PostEdit,Title,Type,Category,Content,Post,Discard,Text,Audio,Video,Image} from "../../constant";
import NewsService from "../../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
const BlogPost = () => {

  const[imageUploaded,setimageUploaded]=useState(false)

  const [title_uz,settitle_uz]=useState('');
  const [description_uz,setdescription_uz]=useState('');
  const [content_uz,setcontent_uz]=useState('');
  const [title_ru,settitle_ru]=useState('');
  const [description_ru,setdescription_ru]=useState('');
  const [content_ru,setcontent_ru]=useState('');
  const [imageUUID,setimageUUID]=useState('');
  const[showdropzone,setshowdropzone]=useState(true)


  const forceUpdate = useForceUpdate();
  const AddNews =()=>{

    let readynews={
      'title_uz':title_uz,
      'description_uz':description_uz,
      'content_uz':content_uz,
      'title_ru':title_ru,
      'description_ru':description_ru,
      'content_ru':content_ru,
      'imageid':imageUUID
    }

    NewsService.addnews(readynews).then((response)=>{toast.success("News Added")})
    forceUpdate()
  }
  const onChangeInEditor = (event, editor) => {
    const data = editor.getData()

    setcontent_uz(data)

  }
  const updateContent=(newContent)=> {
    setcontent_uz(newContent)
  }

  const onChange=(evt)=>{
    var newContent = evt.editor.getData();
    setcontent_uz(newContent)

  }






    // called every time a file's `status` changes
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {  }

  const fsettile_uz=(event)=>{
    settitle_uz(event.target.value)
  }
  const fsetdescription_uz=(event)=>{
    setdescription_uz(event.target.value)
  }
  const fsettile_ru=(event)=>{
    settitle_ru(event.target.value)
  }
  const fsetdescription_ru=(event)=>{
    setdescription_ru(event.target.value)
  }
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
   let formData=new FormData()
    formData.append("file",files[0].file)

    NewsService.uploadimagetominio(formData).then((response)=>{
      setimageUUID(response.data)
      toast.success("Image has been successfully uploaded")

    })
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)

  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                    <FormGroup>
                      <Label for="validationCustom01">Title_UZ:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required="" onChange={fsettile_uz} name={title_uz}/>
                      <div className="valid-feedback">{"Looks good!"}</div>
                      <Label for="validationCustom01">Title_RU:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required="" onChange={fsettile_ru} name={title_ru}/>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="validationCustom01">Description_UZ:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsetdescription_uz} name={description_uz} />
                      <div className="valid-feedback">{"Looks good!"}</div>
                      <Label for="validationCustom01">Description_RU:</Label>
                      <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title" required=""onChange={fsetdescription_ru} name={description_ru} />
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </FormGroup>
                    {/*<FormGroup>*/}
                    {/*  <Label>{Type}:</Label>*/}
                    {/*  /!*<div className="m-checkbox-inline">*!/*/}
                    {/*  /!*  <Label for="edo-ani">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />{Text}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*  <Label for="edo-ani1">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />{Image}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*  <Label for="edo-ani2">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" defaultChecked />{Audio}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*  <Label for="edo-ani3">*!/*/}
                    {/*  /!*    <Input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani" />{Video}*!/*/}
                    {/*  /!*      </Label>*!/*/}
                    {/*  /!*</div>*!/*/}
                    {/*</FormGroup>*/}
                    {/*<FormGroup>*/}
                    {/*  <div className="col-form-Label">{Category}:*/}
                    {/*        <Typeahead*/}
                    {/*          id="multiple-typeahead"*/}
                    {/*          className="mt-2"*/}
                    {/*          clearButton*/}
                    {/*          defaultSelected={data.slice(0, 5)}*/}
                    {/*          labelKey="name"*/}
                    {/*          multiple*/}
                    {/*          options={data}*/}
                    {/*          placeholder="Select Your Name...."*/}
                    {/*        />*/}
                    {/*  </div>*/}
                    {/*</FormGroup>*/}
                    <div className="email-wrapper">
                      <div className="theme-form">
                        <FormGroup>
                          <Label>Content_UZ:</Label>
                          <CKEditor
                              data="<p>Editor's content</p>"
                              onChange={evt => setcontent_uz( evt.editor.getData() )}
                          />
                          <Label>Content_RU:</Label>
                          <CKEditor
                              data="<p>Editor's content</p>"
                              onChange={evt => setcontent_ru( evt.editor.getData() )}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Form>
                <Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*"
                        maxFiles={1}
                        canCancel={false}
                        multiple={false}

                    />
                    {/*<Dropzone*/}
                    {/*    getUploadParams={getUploadParams}*/}
                    {/*    onChangeStatus={handleChangeStatus}*/}
                    {/*    onSubmit={handleSubmit}*/}
                    {/*    accept="image/*"*/}

                    {/*  maxFiles={1}*/}
                    {/*  multiple={false}*/}
                    {/*  canCancel={false}*/}
                    {/*  inputContent="Drop files here or click to upload."*/}
                    {/*  styles={{*/}
                    {/*    dropzone: { width: '100%', height: 50 },*/}
                    {/*    dropzoneActive: { borderColor: 'green' },*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </div>
                </Form>
                <div className="btn-showcase">
                  {imageUploaded&&<Button color="primary" type="submit" onClick={AddNews}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default BlogPost
