
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class VideoService {
    uploadimagetominio(image)
    {return instance.post(`${API_URL}/api/admin/videofile/upload/`, image, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        withCredentials:true
    })}

    addnews(news)
    {
        return instance.post(`${API_URL}/api/admin/videogallery/`, news)
    }

    getAllNews()
    {
        return instance.get(`${API_URL}/api/admin/videogallery/`,{
            withCredentials:true
        })
    }
    updateNews(news)
    {
        return instance.put(`${API_URL}/api/admin/videogallery/`, news)
    }

    deleteNews(news)
    {
        return instance.post(`${API_URL}/api/admin/videogallery/delete/`,news)
    }

}
export default new VideoService()
