import instance from './api';
import {API_URL} from '../../Constants'
import {createBrowserHistory} from "history";

export const USER_NAME_SESSION_ATTRIBUTE_NAME='authenticatedUser'
instance.defaults.withCredentials = true
const historyForce=createBrowserHistory({
    forceRefresh: true
});

class AuthenticationService {

    checkIfUsernameExists(user)
    {
        return instance.post(`${API_URL}/api/authenticate/checkUsername`,user)
    }

    checkIfEmailExists(user)
    {
        return instance.post(`${API_URL}/api/authenticate/checkEmail`,user)
    }
    registerNewUser(user){
        return instance.post(`${API_URL}/api/authenticate`,user)
    }
    signup(user)
    {
        return instance.post(`${API_URL}/api/auth/signup`,user)
    }
    createJwtToken(token)
    {
        return 'Bearer '+token
    }
    getLoggedInUserName(){
        let sessionUser =sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        let localUser =localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if(sessionUser===null&&localUser===null)   return ''
        else if(sessionUser===null&&localUser!==null)
            return localUser
        else
            return sessionUser
    }
    logout(){
        return instance.get(`${API_URL}/api/auth/logout/`)

    }
    signin(user)
    {
        return  instance.post(`${API_URL}/api/auth/signin/`,user)
    }
    isUserLoggedIn(){
        let sessionUser =sessionStorage.getItem("LoggedIn")
        let localUser=localStorage.getItem("LoggedIn")
        if(sessionUser==="true" || localUser==="true")
            return true
        else
            return false
    }
    sessionregisterSuccessfulLoginForJwt(username,token)
    {
        // sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME,username);
        //
        // sessionStorage.setItem('token',this.createJwtToken(token))
        // this.setupAxiosInterceptors(this.createJwtToken(token))
        sessionStorage.setItem("loggedin","true");

    }
    localregisterSuccessfulLoginForJwt(username,token)
    {
        localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME,username);
        this.setupAxiosInterceptors(this.createJwtToken(token))

    }
    setupAxiosInterceptors(token)
    {

        instance.interceptors.request.use(
            (config)=>{
                if(this.isUserLoggedIn()){
                    config.headers.Authorization=token
                }
                return config
            }
        )
    }
    updateUser(user)
    {
        return instance.post(`${API_URL}/api/admin/user`,user)
    }
    resetPassword(user)
    {
        return instance.post(`${API_URL}/api/auth/resetpassword`,user)
    }

    getUserDetails(username)
    {
        return instance.get(`${API_URL}/api/admin/user/details?username=${username}`)
    }

    resetPasswordFinal(resetpasswordform)
    {
        return instance.post(`${API_URL}/api/auth/resetpassword/final`,resetpasswordform)
    }
    isFirst()
    {

        instance.get(`${API_URL}/api/auth/check/`).then((r)=>
        {
            return false
        }).catch((e)=>{
            return true
        })
    }
    refreshAccessToken()
    {
        return instance.get(`${API_URL}/api/auth/refreshtoken/`)
    }

    setFirstFalse()
    {
        sessionStorage.setItem("first","false");
    }
    setFirstTrue()
    {
        sessionStorage.setItem("first","true");
    }
    check222()
    {
        return instance.get(`${API_URL}/api/auth/check/`)
    }
}

export default new AuthenticationService()
