import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  UsersCountryMenu2, Languages, IsActive
} from "../../constant";
import NewsService from "../../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import AuthorityService from "../../api/Axios/AuthorityService";
const AddPrincipal = () => {

  const[imageUploaded,setimageUploaded]=useState(false)
  const [name,setname]=useState('');
  const [surname,setsurname]=useState('');
  const [patronymic,setpatronymic]=useState('');
  const [content,setcontent]=useState('');
  const [imageUUID,setimageUUID]=useState('');
  const[showdropzone,setshowdropzone]=useState(true)
  const[active,setactive]=useState(false)
  const[lang,setlang]=useState('')
  const[id,setid]=useState('')
  const[position,setposition]=useState('')
  const[ord,setord]=useState('')
  const forceUpdate = useForceUpdate();
  
  const addPrincipal =()=>{
    let readyPrincipal={
      'id':id,
      'name':name,
      'surname':surname,
      'patronymic':patronymic,
      'position':position,
      'content':content,
      'lang':lang,
      'imageid':imageUUID,
      'active':active,
      'ord':ord
    }

    if(readyPrincipal.lang==='')
      readyPrincipal.lang='kirill'

    AuthorityService.add(readyPrincipal).then((response)=>{toast.success("Principal Added")
      setTimeout(function(){window.location.reload()}, 2000);})

  }
  const onChangeInEditor = (event, editor) => {
    const data = editor.getData()

    setcontent(data)

  }
  const updateContent=(newContent)=> {
    setcontent(newContent)
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


  const handleChangeStatus = ({ meta, file }, status) => {  }

  const setLang = (event) => {
    setlang(event.target.value)
  }
  const setName=(event)=>{
    setname(event.target.value)
  }
  const setPosition=(event)=>{
    setposition(event.target.value)
  }
  const setSurname=(event)=>{
    setsurname(event.target.value)
  }
  const setPatronymic=(event)=>{
    setpatronymic(event.target.value)
  }

  const setActive=(event)=>{
    setactive(event.target.value)
  }
  const setOrd=(event)=>{
    setord(event.target.value)
  }

  const handleSubmit = (files, allFiles) => {
   let formData=new FormData()
    formData.append("file",files[0].file)

    AuthorityService.uploadimage(formData).then((response)=>{
      setimageUUID(response.data)
      toast.success("Image has been successfully uploaded")

    })
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)

  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                    <FormGroup>
                      <Label className="form-label">Language</Label>
                      <Input type="select" name="select" className="form-control btn-square" onChange={setLang}>
                        {Languages.map((items,i) =>
                            <option key={i}  value={items.valueOf()} onChange={setLang} >{items}</option>
                        )}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label">Is Active</Label>
                      <Input type="select" name="select" className="form-control btn-square" onChange={setActive}>
                        {IsActive.map((items,i) =>
                            <option key={i} value={items.valueOf()} onChange={setActive}>{items.toString()}</option>
                        )}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="validationCustom01">Name</Label>
                      <Input className="form-control" id="validationCustom01" type="text"  onChange={setName} />

                      <Label for="validationCustom01">Surname</Label>
                      <Input className="form-control" id="validationCustom02" type="text"  onChange={setSurname}/>

                      <Label for="validationCustom01">Patronymic</Label>
                      <Input className="form-control" id="validationCustom03" type="text" onChange={setPatronymic}/>

                      <Label for="validationCustom01">Position</Label>
                      <Input className="form-control" id="validationCustom04" type="text"  onChange={setPosition}/>

                      <Label for="validationCustom01">Order</Label>
                      <Input className="form-control" id="validationCustom05" type="text"  onChange={setOrd}/>

                    </FormGroup>


                    <div className="email-wrapper">
                      <div className="theme-form">
                        <FormGroup>
                          <Label>Content</Label>
                          <CKEditor
                              data=""
                              onChange={evt => setcontent( evt.editor.getData() )}
                          />

                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Form>
                <Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    Image(1080x1416):
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*"
                        maxFiles={1}
                        canCancel={false}
                        multiple={false}

                    />

                  </div>
                </Form>
                <div className="btn-showcase">

                  {imageUploaded&&<Button color="primary" type="submit" onClick={addPrincipal}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AddPrincipal
