import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import Select from 'react-select'
import {
    MyProfile,
    Bio,
    MarkJecno,
    Designer,
    Password,
    Website,
    Save,
    EditProfile,
    Company,
    Username,
    UsersCountryMenu,
    UsersCountryMenu2,
    AboutMe,
    UpdateProfile,
    UsersTableTitle,
    FirstName,
    LastName,
    Address,
    EmailAddress,
    PostalCode,
    Country,
    UsersTableHeader,
    City,
    Edit,
    Update,
    Delete,
    Languages, IsActive, ManageGalleryJobPicturesNames, ManageNewsNames
} from '../../constant'
import UsersService from "../../api/Axios/UsersService";
import {setLanguage} from "react-switch-lang";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {toast} from "react-toastify";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import {accessToken} from "../../api/auth/AuthenticationService";
import {API_URL} from "../../Constants";
import AuthorityService from "../../api/Axios/AuthorityService";

import NewsService from "../../api/Axios/NewsService";
import Dropzone from 'react-dropzone-uploader'
import CKEditor from "ckeditor4-react";
import GalleryJobPictureService from "../../api/Axios/GalleryJobPictureService";
import GalleryPictureService from "../../api/Axios/GalleryPictureService";
const ManageNews = (props) => {

    const localeoptions = [
        { label: 'kirill', value: 'kirill' },
        { label: 'uz', value: 'uz' },
        { label: 'ru', value: 'ru' },

    ]
    const[jobpics,setjobpics]=useState([])
    const [jobpic,setjobpic]=useState([])
    const[description,setdescription]=useState('')
    const[family,setfamily]=useState('')
    const[familypics,setfamilypics]=useState([])
    const[ref,setref]=useState(0)
    const[mainp,setmainp]=useState('main')
    const[imageUploaded,setimageUploaded]=useState(false)
    const [content,setcontent]=useState('');
    const [title,settitle]=useState('');
    const [imageUUID,setimageUUID]=useState([]);
    const[showdropzone,setshowdropzone]=useState(true)
    const[locale,setlocale]=useState('kirill')
    const[mainimage,setmainimage]=useState('')


    useEffect(() => {
   NewsService.getAllNews().then( (res)=>{
        setjobpics(res.data)
    })


  },[])

    const updatePic=(event,p)=>{
        event.preventDefault()
        p.title=title
        p.content=content
        p.locale=locale
        p.mainimage=mainimage
        p.images=imageUUID
        NewsService.updateNews(p).then((res)=>{
            toast.success("News have been updated Successfully")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }
    const deleteOnePic=(event,p,pid)=>{
        event.preventDefault()
        NewsService.deleteOneOtherImage(p,pid).then((res)=>{
            toast.success("Other image has been deleted successfully")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })

    }
    const deleteGroupPic=(event,p)=>{
        event.preventDefault()
        NewsService.deleteNews(p).then((res)=>{
            toast.success("News have been deleted successfully")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }
    const handleSubmitMainImage = (files, allFiles) => {
        let formData=new FormData()
        formData.append("file",files[0].file)

        AuthorityService.uploadimage(formData).then((response)=>{
            setmainimage(response.data)
            toast.success("Image has been uploaded successfully")

        })
        allFiles.forEach(f => f.remove())

        setshowdropzone(false)

    }
    const handleSubmit = (files, allFiles) => {

        for(let i=0;i<files.length;i++)
        {
            let formData=new FormData()
            formData.append("file",files[i].file)
            GalleryPictureService.uploadimagetominio(formData).then((response)=>{
                imageUUID.push(response.data)
                toast.success("Picture Added Successfully")
            })
        }
        allFiles.forEach(f => f.remove())
    }

    const editbutton22=(event,jobpica)=>{

        event.preventDefault()
        setjobpic(jobpica)
        setlocale(jobpica.locale)
        settitle(jobpica.title)
        setmainimage(jobpica.mainimage)
        setimageUUID(jobpica.images)
        setcontent(jobpica.content)

    }



    return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle} </h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                    <tr>
                      {ManageNewsNames.map((items,i) =>
                          <th key={i}>{items}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>
                    {jobpics.map((jobpic,i) =>
                        <tr key={i}>
                          <td>{jobpic.id} </td>
                          <td>{jobpic.locale}</td>
                          <td>{jobpic.title}</td>

                          <td className="text-right">
                            <Popup onOpen={(event)=>editbutton22(event,jobpic)}  trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                              <Container fluid={true}>
                                <Card style={{
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflowY: 'auto'
                                }}>
                              <Col xl="8">
                                <Form className="card">
                                  <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col >
                                        <FormGroup>
                                          <Label className="form-label">Id</Label>
                                          <Input className="form-control" type="text" defaultValue={jobpic.id} />
                                        </FormGroup>

                                        <FormGroup>
                                          <Label className="form-label">Title</Label>
                                          <Input className="form-control" type="email" defaultValue={jobpic.title} onChange={(event)=>settitle(event.target.value)} />
                                        </FormGroup>

                                          <FormGroup>
                                              <Label className="form-label">Language</Label>
                                              <Select
                                                  options={localeoptions}
                                                  onChange={value => setlocale(value.value)}
                                                  defaultValue={{ label: jobpic.locale, value: jobpic.locale }}
                                              />
                                          </FormGroup>
                                          <div>
                                              Main Image
                                           <img style={{width: "270px", height: "354px"}}src={`${API_URL}/api/images/` + jobpic.mainimage}/>
                                          </div>
                                          <Form className="m-b-20">
                                              Change Main Image
                                              <div className="m-0 dz-message needsclick">
                                                  <Dropzone
                                                      onSubmit={handleSubmitMainImage}
                                                      accept="image/*"
                                                      maxFiles={1}
                                                      canCancel={true}
                                                      multiple={false}
                                                  />
                                              </div>
                                          </Form>

                                          {jobpic.images.map((fp,k)=>
                                              <div>
                                                Other Images
                                              <div style={{display: 'flex'}} key={k}><img style={{width: "270px", height: "354px"}}
                                                                               src={`${API_URL}/api/images/` + fp}/>
                                              <Button color="danger" size="sm" onClick={e => deleteOnePic(e,jobpic, fp)}><i
                                                  className="fa fa-trash"></i> {Delete}</Button>

                                          </div></div>)}

                                          <Form className="m-b-20">
                                              Add Other Images
                                              <div className="m-0 dz-message needsclick">
                                                  <Dropzone
                                                      onSubmit={handleSubmit}
                                                      accept="image/*"
                                                      canCancel={false}
                                                      multiple={true}
                                                  />
                                              </div>
                                          </Form>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e=>updatePic(e,jobpic)}>{UpdateProfile}</button>
                                  </CardFooter>
                                </Form>
                              </Col>
                                </Card>
                              </Container>
                            </Popup>
                            {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                            <Button color="danger" size="sm" onClick={e=>deleteGroupPic(e,jobpic)}><i className="fa fa-trash"></i> {Delete}</Button>
                          </td>
                        </tr>
                    )}

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>


          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default ManageNews;
