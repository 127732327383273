import React, {Fragment, useState} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  Languages
} from "../constant";
import NewsService from "../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import GalleryPictureService from "../api/Axios/GalleryPictureService";
import { v4 as uuidv4 } from 'uuid';
import GalleryJobPictureService from "../api/Axios/GalleryJobPictureService";
import Select from "react-select";
import GalleryPictureFactoryService from "../api/Axios/GalleryPictureFactoryService";
const FactoryPicturePost = () => {

  const factoryoptions = [
    { label: 'Chaqiqtosh Zavodi', value: 'ZAVOD_CHAQIQTOSH' },
    { label: 'Asvaltobeton Zavodi', value: 'ZAVOD_ASVALTOBETON' },
    { label: 'Xitoy Asvaltobeton zavodi', value: 'ZAVOD_XITOYASVALTOBETON' },
    { label: 'Beton Zavodi', value: 'ZAVOD_BETON' },
  ]

  const localeoptions = [
    { label: 'kirill', value: 'kirill' },
    { label: 'uz', value: 'uz' },
    { label: 'ru', value: 'ru' },

  ]
  const[imageUploaded,setimageUploaded]=useState(false)
  const [description,setdescription]=useState('');
  const [imageUUID,setimageUUID]=useState([]);
  const[showdropzone,setshowdropzone]=useState(true)
  const[locale,setlocale]=useState('kirill')
  const[typ,settyp]=useState('ZAVOD_CHAQIQTOSH')


  const AddFactoryPictures =()=>{

    let factory={
      locale:locale,
      description:description,
      image:imageUUID,
      typ:typ.value
    }

      GalleryPictureFactoryService.add(factory).then((res)=>{
        toast.success("Factory pictures have been added successfully!")
        setTimeout(function () {
          window.location.reload()
        },2000)
      })
   }


  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


  const handleChangeStatus = ({ meta, file }, status) => {  }


  const fsetdescription=(event)=>{
    setdescription(event.target.value)
  }

  const handleSubmit = (files, allFiles) => {

    for(let i=0;i<files.length;i++)
    {
      let formData=new FormData()
      formData.append("file",files[i].file)
      GalleryPictureService.uploadimagetominio(formData).then((response)=>{
      imageUUID.push(response.data)
      if(i===files.length-1){
      toast.success("Pictures Added Successfully")
      }
    })
    }
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
    setshowdropzone(false)

  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Gallery" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Factory Pictures</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">


                    <FormGroup>
                      <Label className="form-label">Language</Label>
                      <Select
                          options={localeoptions}
                          onChange={value => setlocale(value)}
                          defaultValue={{ label:'kirill', value: 'kiril'}}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="form-label">Factory Type</Label>
                      <Select
                          options={factoryoptions}
                          onChange={value => settyp(value)}
                          defaultValue={{ label: 'Chaqiqtosh Zavodi', value: 'ZAVOD_CHAQIQTOSH'  }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="validationCustom01">Description:</Label>
                      <Input className="form-control"  type="text"  required=""onChange={fsetdescription}  />
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </FormGroup>

                  </Col>
                </Form>
                {!imageUploaded&&<Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*"
                        canCancel={true}
                        multiple={true}
                    />

                  </div>
                </Form>}
                <div className="btn-showcase">
                  {imageUploaded&&<Button color="primary" type="submit" onClick={AddFactoryPictures}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default FactoryPicturePost
