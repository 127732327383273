
import {API_URL} from "../../Constants";
import instance from "../auth/api";

instance.defaults.withCredentials = true
class RoadTypeLengthService {

    get()
    {
        return instance.get(`${API_URL}/api/admin/roadtypelength/`,{
            withCredentials:true
        })
    }

   }
export default new RoadTypeLengthService()
