import React, {Fragment, useState, useEffect} from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import {auth0} from './data/config';
import {Switch, Redirect} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {Auth0Provider} from '@auth0/auth0-react'
import store from './store'
import App from './components/app'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {routes} from './route';
import ConfigDB from './data/customizer/config'
import Signin from './api/auth/signin'
import {classes} from './data/layouts';
import {Router, Route, Link} from 'react-router-dom';
import history from './history';

import signin2 from "./api/auth/signin2";


const Root = (props) => {

    const [anim, setAnim] = useState("");
    const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
    const abortController = new AbortController();
    const jwt_token = sessionStorage.getItem('token');
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    const [loggedin,setloggedin]=useState(false)

    useEffect(()=>{

        if(sessionStorage.getItem('loggedin')==='true'&&sessionStorage.getItem('first')==='true') {
            sessionStorage.setItem('first','false')
            history.push("/dashboard/default/Admin")
        }

    },[])


    return (
        <Fragment>
            <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
                <Provider store={store}>
                    <Router history={history}>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin}/>
                            <Route path={`${process.env.PUBLIC_URL}/login2`} component={signin2}/>
                            {sessionStorage.getItem('loggedin')==='true'||loggedin===true ?
                                (<App>
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (
                                            <Redirect to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}/>)
                                    }}/>
                                    <TransitionGroup>
                                        {routes.map(({path, Component}) => (
                                            <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                                                {({match}) => (
                                                    <CSSTransition
                                                        in={match != null}
                                                        timeout={100}
                                                        classNames={anim}
                                                        unmountOnExit>
                                                        <div><Component/></div>
                                                    </CSSTransition>
                                                )}
                                            </Route>
                                        ))}
                                    </TransitionGroup>
                                </App>)
                                :
                                ( <Redirect to={`${process.env.PUBLIC_URL}/login`}/> )
                            }
                        </Switch>
                    </Router>
                </Provider>
            </Auth0Provider>

        </Fragment>
    )
}

ReactDOM.render(<Root/>,
    document.getElementById('root')
);

serviceWorker.unregister();
export default history