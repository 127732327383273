import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  UsersCountryMenu2, Languages, IsActive
} from "../../constant";
import NewsService from "../../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import AuthorityService from "../../api/Axios/AuthorityService";
import AboutUsService from "../../api/Axios/AboutUsService";
const AddAboutUs = () => {

  const [content,setcontent]=useState('');
  const[lang,setlang]=useState('kirill')

  const add =(event)=>{
    event.preventDefault()

    let aboutus={
      locale:lang,
      content:content
    }
   AboutUsService.addAboutUs(aboutus).then((response)=>{
     toast.success("Content Added")
         setTimeout(function(){window.location.reload()}, 2000);}
     ).catch((error)=>{toast.error(error.response.data)})

  }
  const onChangeInEditor = (event, editor) => {
    const data = editor.getData()

    setcontent(data)

  }
  const updateContent=(newContent)=> {
    setcontent(newContent)
  }

  const setLang = (event) => {
    setlang(event.target.value)
  }


  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                    <FormGroup>
                      <Label className="form-label">Language</Label>
                      <Input type="select" name="select" className="form-control btn-square" onChange={setLang}>
                        {Languages.map((items,i) =>
                            <option key={i}  value={items.valueOf()} onChange={setLang} >{items}</option>
                        )}
                      </Input>
                    </FormGroup>


                    <div className="email-wrapper" >
                      <div className="theme-form">
                        <FormGroup>
                          <Label>Content</Label>
                          <CKEditor
                              data=""
                              onChange={evt => setcontent( evt.editor.getData() )}

                          />

                        </FormGroup>
                        <Button color="primary" type="submit" onClick={(event)=>add(event)}>{Post}</Button>
                      </div>
                    </div>
                  </Col>
                </Form>


              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AddAboutUs
