import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class GalleryPictureFactoryService {

    getAll() {
        return instance.get(`${API_URL}/api/admin/factoryimage/`)
    }

    delete(pic) {
        return instance.post(`${API_URL}/api/admin/factoryimage/delete/`, pic)
    }

    add(gPicture) {
        return instance.post(`${API_URL}/api/admin/factoryimage/`, gPicture)
    }

    update(gPicture) {
        return instance.put(`${API_URL}/api/admin/factoryimage/`, gPicture)
    }

    deleteOnePic(jobpic,onepic)
    {
        return instance.post(`${API_URL}/api/admin/factoryimage/delete/one/${onepic}`, jobpic)
    }

}
export default new GalleryPictureFactoryService()
