
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class RoadInfoService {



    update(object)
    {
        return instance.post(`${API_URL}/api/admin/roadtypelength/`, object)
    }

    importExcel(file)
    {
        return instance.post(`${API_URL}/api/admin/allroadsforbranch/`, file)
    }



}
export default new RoadInfoService()
