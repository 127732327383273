
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class NewsService {

    uploadimagetominio(image)
    {return instance.post(`${API_URL}/api/files/upload`, image, {
        headers: {
            "Content-type": "application/json",
            'Content-Type': 'multipart/form-data'
        }
    })}

    addnews(news)
    {
        return instance.post(`${API_URL}/api/admin/news/`, news)
    }

    getAllNews()
    {
        return instance.get(`${API_URL}/api/admin/news/`)
    }
    updateNews(news)
    {
        return instance.put(`${API_URL}/api/admin/news/`, news)
    }

    deleteNews(news)
    {
        return instance.post(`${API_URL}/api/admin/news/delete/`,news)
    }
    deleteOneOtherImage(news,imageid)
    {
        return instance.post(`${API_URL}/api/admin/news/otherimages/delete/${imageid}`,news)
    }
}
export default new NewsService()
