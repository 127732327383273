import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import {
    MyProfile,
    Bio,
    MarkJecno,
    Designer,
    Password,
    Website,
    Save,
    EditProfile,
    Company,
    Username,
    UsersCountryMenu,
    UsersCountryMenu2,
    AboutMe,
    UpdateProfile,
    UsersTableTitle,
    FirstName,
    LastName,
    Address,
    EmailAddress,
    PostalCode,
    Country,
    UsersTableHeader,
    City,
    Edit,
    Update,
    Delete,
    UsersTableHeaderOrg
} from '../../constant'
import UsersService from "../../api/Axios/UsersService";
import {setLanguage} from "react-switch-lang";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {toast} from "react-toastify";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import {accessToken} from "../../api/auth/AuthenticationService";
import {API_URL} from "../../Constants";
import Select from "react-select";
import AuthorityService from "../../api/Axios/AuthorityService";

const UserEdit = (props) => {

    const activeoptions = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' }
    ]

    const roleslist = [
        { label: 'User', value: 'ROLE_USER' },
        { label: 'Admin', value: 'ROLE_ADMIN' }
    ]

    const[defaddingrole,setdefaddingrole]=useState('ROLE_USER')
  const [data,setData] = useState([])
  const [users,setUsers]=useState([])
    const[hasusers,sethasusers]=useState(false)
  const [showUserEdit,setUserEdit]=useState(false)
  const[user11,setUser11]=useState({
      email:'',
      tel:'',
      firstname:'',
      lastname:'',
      password:'',
      erole:'',
      enabled:''

  })
    const[u,setUser]=useState([])

  const updateUser=(event,u)=>{
      event.preventDefault()
      if(user11.email==='')
          user11.email=u.email
      if(user11.tel==='')
          user11.tel=u.tel
      if(user11.firstname==='')
          user11.firstname=u.firstname
      if(user11.lastname==='')
          user11.lastname=u.lastname
      if(user11.password==='')
          user11.password=u.password

      if(user11.enabled==='')
          user11.enabled=u.enabled

    UsersService.updateUser(user11).then(
        (response)=>{
          toast.success("User has been successfully updated")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    ).catch(
        function (error) {
            toast.error(error)
        }
    )

  }

  const deleteUser1=(x,user)=>{

    UsersService.deleteUser(user).then(
        (response)=>{
          toast.success("User has been successfully deleted")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    )
        .catch((err)=>toast.error(err.response))

  };


  useEffect(() => {
     UsersService.getUsersFromDatabase().then((response)=>{

          setUsers(response.data)
            sethasusers(true)
      })
  },[])

   const setfirstName=(event)=>{
       user11.firstname=event.target.value
   };
    const setlastName=(event)=>{
        user11.lastname=event.target.value
    };
    const setuserName=(event)=>{
        user11.email=event.target.value
    };
    const setPassword=(event)=>{
        user11.password=event.target.value
    };

    const setrole=(event)=>{
        user11.erole=event.target.value
    };
    const settel=(event)=>{
        user11.tel=event.target.value
    }

    const setenabled=(event)=> {
        user11.enabled=event.target.value
    }
    const  handleChangeactive=(value)=> {

        user11.enabled=value.value

    }

    const deleteRole=(event,r)=>{
        let payload={
            userid:u.id,
            role:r
        }
        UsersService.deleteRole(payload).then((res)=>{
            toast.success("Role has been deleted successfully!")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }
    const addRole=(event)=>{
        let payload={
            userid:u.id,
            role:defaddingrole
        }
        UsersService.addRole(payload).then((res)=>{
            toast.success("Role has been added successfully!")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }

    return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                    <tr>
                      {UsersTableHeaderOrg.map((items,i) =>
                          <th key={i}>{items}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>
                    {hasusers&&users.map((user,i) =>
                        <tr key={i}>
                          <td><a className="text-inherit" href="#javascript">{user.id} </a></td>
                          <td>{user.firstName}</td>
                          <td><span className="status-icon bg-success"></span>{user.lastName}</td>
                          <td>{user.password}</td>
                          <td>{user.email}</td>
                          <td>{user.enabled.toString()}</td>
                          <td>{user.tel}</td>
                          <td>{user.roles.map((role)=><div>{role.name}&nbsp;</div>)}</td>
                          <td className="text-right">
                            <Popup onOpen={()=>setUser(user)} trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                              <Container fluid={true}>
                                <Card style={{
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflowY: 'auto'
                                }}>
                              <Col xl="8">
                                <Form className="card">
                                  <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col >
                                        <FormGroup>
                                          <Label className="form-label">Id</Label>
                                          <Input className="form-control" type="text" defaultValue={user.id} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">First Name</Label>
                                          <Input className="form-control" type="text" defaultValue={user.firstName} onChange={setfirstName} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Last Name</Label>
                                          <Input className="form-control" type="email" defaultValue={user.lastName} onChange={setlastName} />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Username </Label>
                                          <Input className="form-control" type="text" defaultValue={user.email}  onChange={setuserName}  />
                                        </FormGroup>


                                        <FormGroup>
                                          <Label className="form-label">Password</Label>
                                          <Input className="form-control" type="text" placeholder="New Password" onChange={setPassword} />
                                        </FormGroup>




                                        <FormGroup>
                                          <Label className="form-label">Tel</Label>
                                          <Input className="form-control" type="text" defaultValue={user.tel} onChange={settel} />
                                        </FormGroup>


                                        {/*<FormGroup>*/}
                                        {/*  <Label className="form-label">Roles</Label>*/}
                                        {/*  <Input className="form-control" type="number" placeholder={roles} />*/}
                                        {/*</FormGroup>*/}

                                          <FormGroup>
                                              <Label className="form-label">Is Active</Label>
                                              <Select
                                                  options={activeoptions}
                                                  onChange={value => handleChangeactive(value)}
                                                  defaultValue={{ label: user.enabled.toString(), value: user.enabled }}
                                              />
                                          </FormGroup>

                                        <FormGroup>
                                          <Label className="form-label">Current roles</Label>
                                            {user.roles.map( (r,k)=><div>{r.name}<Button color="danger" size="sm" onClick={e => deleteRole(e, k)}><i
                                                className="fa fa-trash"></i> {Delete}</Button></div>)}
                                        </FormGroup>

                                          <FormGroup>
                                              <Label className="form-label">Add role</Label>
                                              <Select
                                                  options={roleslist}
                                                  onChange={value => setdefaddingrole(value.value)}
                                                  defaultValue={{ label: 'User', value: 'ROLE_USER' }}
                                              />
                                              <Button color="warning" size="sm" onClick={e => addRole(e)}><i
                                                  className="fa fa-trash"></i> Add role</Button>
                                          </FormGroup>

                                        {/*<div className="form-group mb-0">*/}
                                        {/*  <Label className="form-label">About</Label>*/}
                                        {/*  <Input type="textarea" className="form-control" rows="5" placeholder="Enter About your description" />*/}
                                        {/*</div>*/}

                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e=>updateUser(e,user)}>{UpdateProfile}</button>
                                  </CardFooter>
                                </Form>
                              </Col>
                                </Card>
                              </Container>
                            </Popup>

                            {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                            <Button color="danger" size="sm" onClick={e=>deleteUser1(e,user)}><i className="fa fa-trash"></i> {Delete}</Button>
                          </td>
                        </tr>
                    )}

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>


          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default UserEdit;
