import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import Select from 'react-select'
import {
    MyProfile,
    Bio,
    MarkJecno,
    Designer,
    Password,
    Website,
    Save,
    EditProfile,
    Company,
    Username,
    UsersCountryMenu,
    UsersCountryMenu2,
    AboutMe,
    UpdateProfile,
    UsersTableTitle,
    FirstName,
    LastName,
    Address,
    EmailAddress,
    PostalCode,
    Country,
    UsersTableHeader,
    City,
    Edit,
    Update,
    Delete,
    Languages, IsActive
} from '../../constant'
import UsersService from "../../api/Axios/UsersService";
import {setLanguage} from "react-switch-lang";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {toast} from "react-toastify";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import {accessToken} from "../../api/auth/AuthenticationService";
import {API_URL} from "../../Constants";
import AuthorityService from "../../api/Axios/AuthorityService";

import NewsService from "../../api/Axios/NewsService";
import Dropzone from 'react-dropzone-uploader'
import CKEditor from "ckeditor4-react";
import GalleryPictureService from "../../api/Axios/GalleryPictureService";
const ManagePrincipals = (props) => {

    const localeoptions = [
        { label: 'kirill', value: 'kirill' },
        { label: 'uz', value: 'uz' },
        { label: 'ru', value: 'ru' },

    ]
    const activeoptions = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' }
    ]

    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [hasusers, sethasusers] = useState(false)
    const [showUserEdit, setUserEdit] = useState(false)
    const [user11, setUser11] = useState({

        id: '',
        name: '',
        surname: '',
        patronymic: '',
        position:'',
        lang:'',
        active:'',
        content:'',
        ord:''

    })
    const[imageUploaded,setimageUploaded]=useState(false)
    const[showdropzone,setshowdropzone]=useState(true)
    const[lang,setlang]=useState('')
    const[id,setid]=useState('')
    const[position,setposition]=useState('')
    const [name,setname]=useState('');
    const [surname,setsurname]=useState('');
    const [patronymic,setpatronymic]=useState('');
    const [image,setimageUUID]=useState('');
    const [active,setactive]=useState('');
    const [content,setcontent]=useState('');
    const [locale,setlocale]=useState('');
    const [ord,setord]=useState('');

    const[u,setUser]=useState()
    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


    const handleChangeStatus = ({ meta, file }, status) => {  }
  const updateUser=(event,u)=>{
      event.preventDefault()

      user11.name=name;
      user11.surname=surname;
      user11.patronymic=patronymic;
      user11.position=position;
      user11.active=active;
      user11.lang=locale;
      user11.imageid=image;
      user11.content=content;
      user11.ord=ord;

      if(user11.id===''||user11.id===null)
          user11.id=u.id
      if(user11.name===''||user11.name===null)
          user11.name=u.name
      if(user11.surname===''||user11.surname===null)
          user11.surname=u.surname
      if(user11.patronymic===''||user11.patronymic===null)
          user11.patronymic=u.patronymic
      if(user11.position===''||user11.position===null)
          user11.position=u.position
      if(user11.active===''||user11.active===null)
          user11.active=u.active
      if(user11.lang===''||user11.lang===null)
          user11.lang=u.locale
      if(user11.imageid===''||user11.imageid===null)
          user11.imageid=u.image
      if(user11.content===''||user11.content===null)
          user11.content=u.content
      if(user11.ord===''||user11.ord===null)
          user11.ord=u.ord


    AuthorityService.update(user11).then(
        (response)=>{
          toast.success("Principal has been successfully updated")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    ).catch(
        function (error) {
            toast.error(error)
        }
    )

  }

  const deleteUser1=(event,x)=>{

    AuthorityService.delete222(x).then(
        (response)=>{
          toast.success("Principal has been successfully deleted")
          setTimeout(function(){window.location.reload()}, 2000);
        }
    )
        .catch(()=>toast.error("error"))

  };


  useEffect(() => {
     AuthorityService.getAll().then((response)=>{

          setUsers(response.data)
            sethasusers(true)
      })
  },[])

   const  handleChangelocale=(value)=> {

        setlocale(value.value)

    }
    const  handleChangeactive=(value)=> {

        setactive(value.value)

    }
    const setName=(event)=>{
        setname(event.target.value)
    }
    const setPosition=(event)=>{
        setposition(event.target.value)
    }
    const setSurname=(event)=>{
        setsurname(event.target.value)
    }
    const setPatronymic=(event)=>{
        setpatronymic(event.target.value)
    }

    const setActive=(event)=>{
        setactive(event.target.value)
    }

    const setLocale=(event)=>{
        setlocale(event.target.value)
    }
    const setOrd=(event)=>{
        setord(event.target.value)
    }
    const handleSubmit = (files, allFiles) => {
        let formData=new FormData()
        formData.append("file",files[0].file)

        GalleryPictureService.uploadimagetominio(formData).then((response)=>{
            setimageUUID(response.data)
            toast.success("Image has been successfully uploaded")

        })
        allFiles.forEach(f => f.remove())
        setimageUploaded(true)
        setshowdropzone(false)

    }

    return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                    <tr>
                      {UsersTableHeader.map((items,i) =>
                          <th key={i}>{items}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>
                    {hasusers&&users.map((user,i) =>
                        <tr key={i}>
                          <td><a className="text-inherit" href="#javascript">{user.id} </a></td>
                          <td>{user.name}</td>
                          <td><span className="status-icon bg-success"></span>{user.surname}</td>
                          <td>{user.patronymic}</td>
                          <td>{user.position}</td>
                          <td>{user.active.toString()}</td>
                          <td>{user.locale}</td>
                            <td>{user.ord}</td>

                          <td className="text-right">
                            <Popup onOpen={()=>setUser(user)} trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                              <Container fluid={true}>
                                <Card style={{
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflowY: 'auto'
                                }}>
                              <Col xl="8">
                                <Form className="card">
                                  <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col >
                                        <FormGroup>
                                          <Label className="form-label">Id</Label>
                                          <Input className="form-control" type="text" defaultValue={user.id} />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label className="form-label">Name</Label>
                                          <Input className="form-control" type="text" defaultValue={user.name} onChange={setName} />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label className="form-label">Surname</Label>
                                          <Input className="form-control" type="email" defaultValue={user.surname} onChange={setSurname} />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label className="form-label">Patronymic </Label>
                                          <Input className="form-control" type="text" defaultValue={user.patronymic}  onChange={setPatronymic}  />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label className="form-label">Position</Label>
                                          <Input className="form-control" type="text" defaultValue={user.position} onChange={setPosition} />
                                        </FormGroup>
                                          <FormGroup>
                                              <Label className="form-label">Order</Label>
                                              <Input className="form-control" type="text" defaultValue={user.ord} onChange={setOrd} />
                                          </FormGroup>

                                          <FormGroup>
                                              <Label className="form-label">Is Active</Label>
                                              <Select
                                                  options={activeoptions}
                                                  onChange={value => handleChangeactive(value)}
                                                  defaultValue={{ label: user.active.toString(), value: user.active }}
                                              />
                                          </FormGroup>

                                          <FormGroup>
                                              <Label className="form-label">Language</Label>

                                              <Select
                                                  options={localeoptions}
                                                  onChange={value => handleChangelocale(value)}
                                                  defaultValue={{ label: user.locale, value: user.locale }}
                                              />
                                          </FormGroup>
                                          <div className="email-wrapper">
                                              <div className="theme-form">
                                                  <FormGroup>
                                                      <Label>Content</Label>
                                                      <CKEditor
                                                          data={user.content}
                                                          onChange={evt => setcontent( evt.editor.getData() )}
                                                      />

                                                  </FormGroup>
                                              </div>
                                          </div>

                                          <Form className="m-b-20">
                                              <div className="m-0 dz-message needsclick">

                                                  <div > <img style={{width:"270px",height:"354px"}} src={`${API_URL}/api/images/`+user.image}/></div>

                                                  <Dropzone
                                                      getUploadParams={getUploadParams}
                                                      onChangeStatus={handleChangeStatus}
                                                      onSubmit={handleSubmit}
                                                      accept="image/*"
                                                      maxFiles={1}
                                                      canCancel={false}
                                                      multiple={false}
                                                  />
                                              </div>
                                          </Form>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e=>updateUser(e,user)}>{UpdateProfile}</button>
                                  </CardFooter>
                                </Form>
                              </Col>
                                </Card>
                              </Container>
                            </Popup>
                            {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                            <Button color="danger" size="sm" onClick={e=>deleteUser1(e,user)}><i className="fa fa-trash"></i> {Delete}</Button>
                          </td>
                        </tr>
                    )}

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>


          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default ManagePrincipals;
