import React, {Fragment, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import CKEditor from 'ckeditor4-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Dropzone from 'react-dropzone-uploader'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap"
import {
  PostEdit,
  Title,
  Type,
  Category,
  Content,
  Post,
  Discard,
  Text,
  Audio,
  Video,
  Image,
  UsersCountryMenu2, Languages, IsActive
} from "../../constant";
import NewsService from "../../api/Axios/NewsService";
import {toast} from "react-toastify";
import useForceUpdate from 'use-force-update';
import AuthorityService from "../../api/Axios/AuthorityService";
import RoadInfoService from "../../api/Axios/RoadInfoService";
const AddRoadInfo = () => {

  const[inter,setinter]=useState('')
  const[stat,setstat]=useState('')
  const[loca,setloca]=useState('')
  const[imageUploaded,setimageUploaded]=useState(false)
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


  const handleChangeStatus = ({ meta, file }, status) => {  }

  const updateRoadInfo=()=>{
    let roadtypelength={
      international:inter,
      state:stat,
      local:loca
    }
    RoadInfoService.update(roadtypelength).then((res)=>{
      toast.success("Data has been imported successfully")
      setTimeout(function () {
        window.location.reload()
      },2000)
    })
  }

  const handleSubmit = (files, allFiles) => {
   let formData=new FormData()
    formData.append("file",files[0].file)
    RoadInfoService.importExcel(formData).then((response)=>{
      toast.success("Excel file has been imported successfully")
    }).catch((error)=>{

      toast.error(error.response.data.message)
    })
    allFiles.forEach(f => f.remove())
    setimageUploaded(true)
  }
  return (
    <Fragment>
      <Breadcrumb parent="Blog" title="Add Post" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{PostEdit}</h5>
              </CardHeader>
              <CardBody className="add-post">
                <Form className="row needs-validation">
                  <Col sm="12">
                    <FormGroup>
                      <Label for="validationCustom01">International Road Length</Label>
                      <Input className="form-control" id="validationroad1" type="text"  onChange={(event)=>setinter(event.target.value)} />

                      <Label for="validationCustom01">State Road Length</Label>
                      <Input className="form-control" id="validationroad2" type="text"  onChange={(event)=>setstat(event.target.value)} />

                      <Label for="validationCustom01">Local Road Length</Label>
                      <Input className="form-control" id="validationroad3" type="text"  onChange={(event)=>setloca(event.target.value)} />

                    </FormGroup>
                <Form className="m-b-20">
                  <div className="m-0 dz-message needsclick">

                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxFiles={1}
                        canCancel={false}
                        multiple={false}

                    />

                  </div>
                </Form>
                  </Col>
                </Form>
                <div className="btn-showcase">

                  {imageUploaded&&<Button color="primary" type="submit" onClick={updateRoadInfo}>{Post}</Button>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AddRoadInfo
