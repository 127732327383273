import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import Select from 'react-select'
import {
    MyProfile,
    Bio,
    MarkJecno,
    Designer,
    Password,
    Website,
    Save,
    EditProfile,
    Company,
    Username,
    UsersCountryMenu,
    UsersCountryMenu2,
    AboutMe,
    UpdateProfile,
    UsersTableTitle,
    FirstName,
    LastName,
    Address,
    EmailAddress,
    PostalCode,
    Country,
    UsersTableHeader,
    City,
    Edit,
    Update,
    Delete,
    Languages, IsActive, ManageGalleryJobPicturesNames, ManageGalleryPictureFactoryNames
} from '../../constant'
import UsersService from "../../api/Axios/UsersService";
import {setLanguage} from "react-switch-lang";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {toast} from "react-toastify";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import {accessToken} from "../../api/auth/AuthenticationService";
import {API_URL} from "../../Constants";
import AuthorityService from "../../api/Axios/AuthorityService";

import NewsService from "../../api/Axios/NewsService";
import Dropzone from 'react-dropzone-uploader'
import CKEditor from "ckeditor4-react";
import GalleryJobPictureService from "../../api/Axios/GalleryJobPictureService";
import GalleryPictureService from "../../api/Axios/GalleryPictureService";
import GalleryPictureFactoryService from "../../api/Axios/GalleryPictureFactoryService";
const ManageGalleryPictureFactory = (props) => {

    const localeoptions = [
        { label: 'kirill', value: 'kirill' },
        { label: 'uz', value: 'uz' },
        { label: 'ru', value: 'ru' },

    ]

    const factoryoptions = [
        { label: 'Chaqiqtosh Zavodi', value: 'ZAVOD_CHAQIQTOSH' },
        { label: 'Asvaltobeton Zavodi', value: 'ZAVOD_ASVALTOBETON' },
        { label: 'Xitoy Asvaltobeton zavodi', value: 'ZAVOD_XITOYASVALTOBETON' },
        { label: 'Beton Zavodi', value: 'ZAVOD_BETON' },
    ]
    const[jobpics,setjobpics]=useState([])
    let [jobpic,setjobpic]=useState({})
    const[description,setdescription]=useState('')

    const[locale,setlocale]=useState('')
    const[imageUUID,setimageUUID]=useState([])



    const[typ,settyp]=useState('')

  useEffect(() => {

    GalleryPictureFactoryService.getAll().then( (res)=>{
        setjobpics(res.data)

    })


  },[])

    const updatePic=(event,p)=>{
        event.preventDefault()
        p.description=description
        p.locale=locale
        p.image=imageUUID
        p.typ=typ

        GalleryPictureFactoryService.update(p).then((res)=>{
            toast.success("Pictures  Updated Successfully")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }
    const deleteOnePic=(event,p,fp)=>{
        event.preventDefault()
        GalleryPictureFactoryService.deleteOnePic(p,fp).then((res)=>{
            toast.success("Picture has been deleted Successfully")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }
    const deleteAll=(event,p)=>
    {  event.preventDefault()
        GalleryPictureFactoryService.delete(p).then((res)=>{
            toast.success("Pictures have been deleted Successfully")
            setTimeout(function () {
                window.location.reload()
            },2000)
        })

    }


     const handleSubmit = (files, allFiles) => {

        for(let i=0;i<files.length;i++)
        {
            let formData=new FormData()
            formData.append("file",files[i].file)
            GalleryPictureService.uploadimagetominio(formData).then((response)=>{
                imageUUID.push(response.data)
                if(i===files.length-1){
                    toast.success("Pictures Added Successfully")
                }
            })
        }
        allFiles.forEach(f => f.remove())


    }

    const editbutton22=(event,jobpica)=>{
        event.preventDefault()
        setdescription(jobpica.description)
        setlocale(jobpica.locale)
        settyp(jobpica.typ)
        setimageUUID(jobpica.image)
    }

    const setLocale=(value)=>{
        setlocale(value.value)
    }


    return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle} </h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                    <tr>
                      {ManageGalleryPictureFactoryNames.map((items,i) =>
                          <th key={i}>{items}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>
                    {jobpics.map((jobpic,i) =>
                        <tr key={i}>
                          <td><a className="text-inherit" href="#javascript">{jobpic.id} </a></td>
                          <td>{jobpic.description}</td>
                          <td>{jobpic.typ}</td>
                          <td>{jobpic.locale}</td>

                          <td className="text-right">
                            <Popup onOpen={(event)=>editbutton22(event,jobpic)}  trigger={ <Button id={i} color="primary" size="sm" > {Edit}</Button>} position="right center" modal nested>
                              <Container fluid={true}>
                                <Card style={{
                                  maxHeight: 'calc(100vh - 210px)',
                                  overflowY: 'auto'
                                }}>
                              <Col xl="8">
                                <Form className="card">
                                  <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col >
                                        <FormGroup>
                                          <Label className="form-label">Id</Label>
                                          <Input className="form-control" type="text" defaultValue={jobpic.id} />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label className="form-label">Description</Label>
                                          <Input className="form-control" type="text" defaultValue={jobpic.description} onChange={(event)=>setdescription(event.target.value)} />
                                        </FormGroup>

                                          <FormGroup>
                                              <Label className="form-label">Language</Label>
                                              <Select
                                                  options={localeoptions}
                                                  onChange={value => setLocale(value)}
                                                  defaultValue={{ label: jobpic.locale, value: jobpic.locale }}
                                              />
                                          </FormGroup>

                                          {jobpic.image.map((fp,k)=>

                                              <div style={{display: 'flex'}} key={k}><img style={{width: "270px", height: "354px"}}
                                                                               src={`${API_URL}/api/images/` + fp}/>
                                              <Button color="danger" size="sm" onClick={e => deleteOnePic(e,    jobpic, fp)}><i
                                                  className="fa fa-trash"></i> {Delete}</Button>


                                          </div>)}

                                          <Form className="m-b-20">
                                              <div className="m-0 dz-message needsclick">
                                                  <Dropzone
                                                      onSubmit={handleSubmit}
                                                      accept="image/*"
                                                      canCancel={true}
                                                      multiple={true}
                                                  />
                                              </div>
                                          </Form>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e=>updatePic(e,jobpic)}>{UpdateProfile}</button>
                                  </CardFooter>
                                </Form>
                              </Col>
                                </Card>
                              </Container>
                            </Popup>
                            {/*<Button color="transparent" size="sm" onClick={()=>updateUser1}><i className="fa fa-link"></i> {Update}</Button>*/}
                            <Button color="danger" size="sm" onClick={e=>deleteAll(e,jobpic)}><i className="fa fa-trash"></i> {Delete}</Button>
                          </td>
                        </tr>
                    )}

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>


          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default ManageGalleryPictureFactory;
