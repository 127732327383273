
import {API_URL} from '../../Constants'
import instance from "../auth/api";

instance.defaults.withCredentials = true
class AboutUsService {

    get()
    {
        return instance.get(`${API_URL}/api/aboutus/`)
    }

    getAll()
    {
        return instance.get(`${API_URL}/api/admin/aboutus/`)
    }

    addAboutUs(object)
    {
        return instance.post(`${API_URL}/api/admin/aboutus/`, object)}

    update(object)
    {
        return instance.put(`${API_URL}/api/admin/aboutus/`, object)
    }
    delete(object)
    {
        return instance.post(`${API_URL}/api/admin/aboutus/delete/`, object)
    }


}
export default new AboutUsService()
